require("bootstrap/dist/css/bootstrap.min.css")

exports.onClientEntry = () => {
  if (typeof window !== "undefined") {
    const script1 = document.createElement("script")
    script1.async = true
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-VX8Y3C9V9M"
    document.head.appendChild(script1)

    const script2 = document.createElement("script")
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-VX8Y3C9V9M');
    `
    document.head.appendChild(script2)
  }
}
